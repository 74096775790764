// extracted by mini-css-extract-plugin
export var author = "leftside-module--author--2odQC";
export var name = "leftside-module--name--2sU2v";
export var designation = "leftside-module--designation--2Souv";
export var date = "leftside-module--date--3Yk9q";
export var read = "leftside-module--read--2vP0c";
export var like = "leftside-module--like--3bSQK";
export var watch = "leftside-module--watch--3hoPa";
export var separator = "leftside-module--separator--2_ow8";
export var tag = "leftside-module--tag--dOE5G";
export var author_cover = "leftside-module--author_cover--Hct1w";
export var card = "leftside-module--card--1-GA2";
export var error = "leftside-module--error--2tpy5";
export var info = "leftside-module--info--1kfms";