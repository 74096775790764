import React, { useState, useEffect } from 'react'
import Layout from '../Layout/Layout'
import RightSide from '../Components/Post/RightSide'
import PostBody from '../Components/Post/PostBody'
import LeftSide from '../Components/Post/LeftSide'
import axios from 'axios'
import TopSide from '../Components/Post/TopSide'
import { graphql } from "gatsby"
import loadable from '@loadable/component'
import ContentLoader from '../Components/ContentLoader/ContentLoader'
import NotFoundPage from './404'

//loadabel seo componenet

const Seo = loadable(() => import('../Components/Seo/Seo'))


const Sticky = typeof window !== `undefined` ? require("sticky-js") : null



const Index = (props) => {
const [notfound, setnotfound] = useState(false)
  // if (!LeftSide) {
  //   return null
  // }


  const [post, setpost] = useState(null)
  const [author, setauthor] = useState(null)

  const [apiLoaded, setapiLoaded] = useState(false)

  const books = props.data.allMongodbDiptncblogBlogPost.nodes;


  const slug = props.params.pid;
  if (typeof window !== `undefined`) {
    var sticky = new Sticky('[data-sticky]');
  }


  const getAuthor = async (username) => {
    const query = { 'function': 'getAuthorDetails', 'username': `${username}` }
    const res = await axios.post(`${process.env.GATSBY_API_FETCH_AUTHOR_DETAILS}`, query)
    setauthor(res.data[0])
    setapiLoaded(true)
  }
  const getPost = async () => {
    const query = { 'function': 'getPostBySlug', 'slug': `${slug}` };
    const res = await axios.post(`${process.env.GATSBY_API_FETCH_POST}`, query)
    if (res.data.length > 0) {
     setpost(res.data[0])
    getAuthor(res.data[0].blog_post_author_username)

    }
  else{
    // redirect to 404
    setnotfound(true)


  }


  }
  useEffect(() => {
    console.log(books)
    getPost()
  }, [])
  return (
    <>

 

{post?(<Seo title={post.blog_post_title} content={post.blog_post_content}/>):null}





      <Layout>

        {notfound ? <NotFoundPage /> :
   
        <div className="container position-relative" data-sticky-container>
          <div className="row">
            {post ? (<TopSide post={post}></TopSide>) : (<ContentLoader ></ContentLoader>)}


            {author && post ? (<LeftSide author={author} post={post}></LeftSide>) : (<ContentLoader ></ContentLoader>)}



            {post ? (<PostBody post={post}></PostBody>) : (<ContentLoader ></ContentLoader>)}

            {post ? (<RightSide blog_title={post.blog_post_title}></RightSide>) : (<ContentLoader ></ContentLoader>
            )}



          </div>
        </div>
}


      </Layout>
    </>
  )
}

export default Index
export const pageQuery = graphql`
{
  allMongodbDiptncblogBlogPost(
    filter: {blog_post_time_stamp: {eq: 1630324641374}}
  ) {
    nodes {
      blog_post_title_slug
      blog_post_watch_count
      blog_post_content
      blog_post_title
    }
  }
}
`

